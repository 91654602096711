import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ShoppingButton from './shoppingButton';
import ShoppingTotal from './shoppingTotal';
import Price from '../components/price';
import colors from '../config/color';

const ShoppingCheckPrice = props => {
  const { text, price } = props;

  return (
    <div className="content">
      <ShoppingTotal text={text} price={price} />
      <div className="shopping-btn">
        <ShoppingButton
          btnStyle={{ paddingRight: 47, paddingLeft: 47 }}
          text="下一步"
        />
      </div>

      <style jsx>{`
        .shopping-btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingCheckPrice.propTypes = {
  text: PropTypes.string,
  price: PropTypes.number,
};

ShoppingCheckPrice.defaultProps = {
  text: '',
  price: '',
};

export default ShoppingCheckPrice;
