import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Price from '../components/price';
import colors from '../config/color';
import {
  IoMdClose,
  IoIosArrowDown,
  IoIosRemove,
  IoIosAdd,
} from 'react-icons/io';

const ShoppingUndecidedCard = props => {
  const { title, price, imageUri, imageAlt } = props;
  const [number, setNumber] = useState(0);

  return (
    <div className="content">
      <div className="ring-img">
        <img className="img-content" alt={imageAlt} src={imageUri} />
      </div>
      <div className="items-content">
        <div className="title-content">
          <h2>{title}</h2>
          <div className="close-icon">
            <IoMdClose style={{ height: 16, width: 16 }} />
          </div>
        </div>
        <div className="typesetting price-content">
          <h3>金額</h3>
          <Price price={price} />
        </div>
        <div className="typesetting spec-content">
          <h3>規格</h3>
          <select className="menu" width="85%">
            <option value="man_12">彈性男性戒圍 (國際戒圍12-15)</option>
            <option value="man_12">彈性男性戒圍 (國際戒圍11-14)</option>
          </select>
          <div className="down-icon">
            <IoIosArrowDown style={{ height: 16, width: 16 }} />
          </div>
        </div>
        <div className="typesetting quantity-content">
          <h3>購買數量</h3>
          <div className="quantity">
            <div
              onClick={() => {
                setNumber(number - 1);
              }}
              className="qty-btn qty-minus"
            >
              <IoIosRemove style={{ height: 22, width: 22 }} />
            </div>
            <input className="qty" value={number}></input>
            <div
              onClick={() => {
                setNumber(number + 1);
              }}
              className="qty-btn qty-plus"
            >
              <IoIosAdd style={{ height: 22, width: 22 }} />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .content {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 36px 0;
          min-width: 343px;
        }

        .items-content {
          width: 100%;
          margin-left: 25px;
          display: flex;
          flex-direction: column;
          position: relative;
        }

        /* title-content */
        h2 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 14px;
          line-height: 20px;
          padding-right: 52px;
          margin-bottom: 7px;
          min-width: 168px;
        }

        .close-icon {
          background-color: #e0e0e0;
          width: 22px;
          height: 22px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
        }

        /* price-content */
        h3 {
          display: flex;
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 12px;
        }

        .typesetting {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 7px 0;
        }

        .price-content {
          align-items: flex-end;
        }

        /* spec-content */
        .spec-content {
          align-items: center;
          position: relative;
        }

        .menu {
          color: ${colors['black-p']};
          background-color: ${colors['white']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 10px;
          border: 1px #e0e0e0 solid;
          border-radius: 5px;
          margin-left: 5px;
          width: 85%;
        }

        option {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .down-icon {
          position: absolute;
          top: 4px;
          right: 2px;
          z-index: 1;
        }

        /* quantity-content */
        .quantity-content {
          align-items: center;
        }

        .quantity {
          display: flex;
          flex-direction: row;
        }

        .qty-btn {
          width: 22px;
          height: 22px;
          background-color: #e0e0e0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .qty-minus {
          color: ${colors['white']};
          border-radius: 5px 0 0 5px;
        }

        .qty-plus {
          border-radius: 0 5px 5px 0;
        }

        .qty {
          width: 20px;
          height: 20px;
          background-color: ${colors['white']};
          border: 0px;
          text-align: center;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingUndecidedCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  number: PropTypes.number,
};

ShoppingUndecidedCard.defaultProps = {
  title: '',
  price: '',
  imageUri: '',
  imageAlt: '',
  number: '',
};

export default ShoppingUndecidedCard;
